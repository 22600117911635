<script lang="ts">
import { defineComponent } from 'vue'
import { useModelWrapper } from '@voix/composables/useModelWrapper'

import SectionLabel from '@voix/components/chrome/SectionLabel.vue'

export default defineComponent({
  components: { SectionLabel },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit, slots }) {
    return { localValue: useModelWrapper(props, emit), props, slots }
  },
})
</script>

<template>
  <div>
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <button
      class="mt-1 mx-1 h-6 w-11 rounded-full inline-block relative outline-none transform duration-100 focus:ring-1 ring-gray-200 ring-offset-1"
      :class="{ 'bg-gray-100 ': !localValue, 'voix-admin-bg-lightest ring-1': localValue }"
      @click="localValue = !localValue"
    >
      <span
        class="block rounded-full h-5 w-5 transition-all transform duration-100 ease-out"
        :class="{
          'translate-x-6 bg-emerald-400': localValue,
          'translate-x-1 bg-gray-300': !localValue,
        }"
      />
    </button>
  </div>
</template>
