<script setup lang="ts">
import PageSelect from '@voix/components/chrome/pages/PageSelect.vue'

import { ref } from 'vue'
import { copyElement } from '@voix/composables/queries/useQueryElements'

import type { SliceInterface } from '@voix/types'

const props = defineProps<{
  slice: SliceInterface
}>()

const emit = defineEmits(['close'])

const page = ref('')

async function copyToPage() {
  if (page.value && props.slice) {
    await copyElement({
      page_id: page.value.id,
      ...props.slice,
    })

    page.value = ''
    emit('close')
  }
}
</script>

<template>
  <div>
    <div class="text-xs uppercase font-medium voix-admin-text">
      Copy this slice to a page
    </div>
    <PageSelect v-model="page" label="Page to copy to" class="mt-4" />

    <div class="flex space-x-2">
      <button
        class="mt-4 voix-admin-bg text-white px-4 py-2 rounded-sm text-xs disabled:opacity-50"
        :disabled="!page"
        @click="copyToPage"
      >
        Copy
      </button>
      <button class="mt-4 bg-gray-200 text-gray-600 px-4 py-2 rounded-sm text-xs" @click="$emit('close')">
        Cancel
      </button>
    </div>
  </div>
</template>
